import { clsx } from 'clsx';
import React from 'react';

import { useTranslate } from '@/hooks/useTranslate';

import styles from './styles.module.css';
import BasedOnRecentDlTile from './Tiles/BasedOnRecentDlTile';
import GenericTile from './Tiles/GenericTile';

import { Slider } from '.';

import type { RecentDownloadsTile, Tile } from '@/types/carousel';

interface SliderCarouselProps {
  title?: string;
  data: (Tile | RecentDownloadsTile)[];
  className?: string;
  hideContainer?: boolean;
  imagePriority?: boolean;
  showDescription?: boolean | 'minimal';
}

const isRecentDownloadsTile = (
  tile: Tile | RecentDownloadsTile,
): tile is RecentDownloadsTile =>
  !('tile_image' in tile) && 'tile_image_left' in tile;

const SliderCarousel = ({
  title,
  data,
  className,
  imagePriority,
  hideContainer,
  showDescription,
}: SliderCarouselProps) => {
  const { t } = useTranslate();

  return (
    <div
      data-testid="slider-carousel"
      className={clsx(
        styles.mainCarouselWrapper,
        hideContainer && styles.containerHidden,
        className,
      )}
    >
      {!!data?.length && (
        <div className={styles.mainCarouselWrapperItem}>
          {!!title && (
            <h2 className={styles.mainCarouselWrapperTitle}>{t(title)}</h2>
          )}
          <Slider>
            {data
              .sort((a, b) => +a.order - +b.order)
              .map((item) =>
                isRecentDownloadsTile(item) ? (
                  <BasedOnRecentDlTile
                    key={`mobile-carousel-${item.id ?? item.sort}`}
                    imagePriority={imagePriority}
                    item={item}
                  />
                ) : (
                  <GenericTile
                    key={`mobile-carousel-${item.id ?? item.sort}`}
                    item={item}
                    showDescription={showDescription}
                  />
                ),
              )}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default SliderCarousel;

import { clsx } from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { SliderArrow } from './SliderArrow';
import { SlideWrapper } from './SlideWrapper';
import styles from './styles.module.css';
import { defaultResponsive } from './util';

import type { CarouselStyles } from './util';
import type { SlideItem } from '@/types/carousel';
import type { ReactNode } from 'react';

interface SliderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactNode[];
  responsiveStyles?: CarouselStyles;
}

export const Slider = ({
  children,
  responsiveStyles = defaultResponsive,
}: SliderProps) => {
  // Carousel container.
  const carousel = useRef<HTMLDivElement>(null);

  // All items in the carousel.
  const carouselItems = useRef<Map<number, SlideItem> | null>(null);

  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);
  const [isAtStart, setIsAtStart] = useState<boolean>(true);

  const getCarouselMap = () => {
    if (!carouselItems.current) {
      // Initialize the Map on first usage.
      carouselItems.current = new Map();
    }

    return carouselItems.current;
  };

  const getCurrentSlide = () => {
    const carouselMap = getCarouselMap();

    const iterator = carouselMap.entries();

    for (let index = 0; index < carouselMap.size; index += 1) {
      const { isVisible } = iterator.next().value[1];

      if (isVisible) {
        return index;
      }
    }

    return 0;
  };

  // Scroll slide into view using slide index.
  const scrollSlideIntoView = (index: number) => {
    const carouselMap = getCarouselMap();

    const newSlide = carouselMap.get(index);

    if (!newSlide) {
      return;
    }

    newSlide.element?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const getSlidesToScroll = () => {
    const carousels = getCarouselMap();

    let visibleTileCount = 0;

    // Get visible tile count.
    carousels.forEach((item) => {
      if (item.isVisible) {
        visibleTileCount += 1;
      }
    });

    return visibleTileCount;
  };

  const onPreviousSlide = () => {
    const currentSlide = getCurrentSlide();

    // Scroll to end of carousel we currently at the start.
    if (isAtStart || currentSlide === 0) {
      carousel.current?.scrollTo({
        left: carousel?.current?.scrollWidth,
        behavior: 'smooth',
      });

      return;
    }

    const slidesToScroll = getSlidesToScroll();
    // If not at the start of the carousel, scroll to backwards by slidesToScroll.
    const newSlide = Math.max(currentSlide - slidesToScroll, 0);

    scrollSlideIntoView(newSlide);
  };

  const onNextSlide = () => {
    const carouselMap = getCarouselMap();
    const currentSlide = getCurrentSlide();

    // If scrolled to the end of the carousel, scroll to the end.
    if (isAtEnd || currentSlide === carouselMap.size - 1) {
      carousel.current?.scrollTo({ left: 0, behavior: 'smooth' });

      return;
    }

    const slidesToScroll = getSlidesToScroll();
    // Scroll forwards by slidesToScroll.
    const newSlide = Math.min(
      currentSlide + slidesToScroll,
      carouselMap.size - 1,
    );

    scrollSlideIntoView(newSlide);
  };

  useEffect(() => {
    const carouselMap = getCarouselMap();

    if (typeof window.IntersectionObserver === 'undefined') {
      setIsAtEnd(false);
      setIsAtStart(false);

      return undefined;
    }

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const elementIndex = parseInt(
          entry.target.getAttribute('data-slide') ?? '',
          10,
        );

        if (Number.isNaN(elementIndex)) {
          console.error('data-slide attribute not set');

          return;
        }

        const currentElement = carouselMap.get(elementIndex);

        carouselMap.set(elementIndex, {
          element: currentElement?.element ?? null,
          isVisible: entry.isIntersecting,
        });

        if (elementIndex === 0) {
          setIsAtStart(entry.isIntersecting);
        }

        if (elementIndex === carouselMap.size - 1) {
          setIsAtEnd(entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      // Small threshold allows fixes issues when slidesToScroll is 1.
      threshold: 0.9,
      root: carousel.current,
    });

    carouselMap.forEach((item) => {
      if (item?.element) {
        observer.observe(item.element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [children]);

  return (
    <div className={styles.slider}>
      <SliderArrow
        className={clsx(
          styles.slickArrow,
          styles.slickPrev,
          isAtStart && styles.disabled,
        )}
        onClick={onPreviousSlide}
        type="previous"
      >
        Previous
      </SliderArrow>

      <div ref={carousel} className={styles.mobileCarousel}>
        <SlideWrapper
          customStyles={responsiveStyles}
          carousel={getCarouselMap()}
        >
          {children}
        </SlideWrapper>
      </div>
      <SliderArrow
        className={clsx(
          styles.slickArrow,
          styles.slickNext,
          isAtEnd && styles.disabled,
        )}
        onClick={() => {
          onNextSlide();
        }}
        type="next"
      >
        Next
      </SliderArrow>
    </div>
  );
};

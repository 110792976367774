import { clsx } from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { DynamicLink } from '@/components/DynamicLink';
import styles from '@/components/Slider/styles.module.css';
import { T } from '@/components/T';
import { PLACEHOLDER_IMG_URL } from '@/constants/images';
import { useTranslate } from '@/hooks/useTranslate';
import { useTracking } from '@/providers/TrackingProvider';

import type { Tile } from '@/types/carousel';
import type { EventAccess } from '@/utils/tracking/types';

interface GenericTileProps {
  item: Tile;
  onClick?: () => void;
  eventAccess?: EventAccess;
  showDescription?: boolean | 'minimal';
}

const TRENDING_DESCRIPTION =
  'Keep your content fresh with our always up-to-date collection of your fellow creators’ most downloaded music and sound effects on Uppbeat.';

const UNDISCOVERED_DESCRIPTION =
  'Uncover the hidden gems at the heart of Uppbeat’s catalog. Be the first to use them in your content and spark the latest soundtrack trends on social media.';

const LATEST_RELEASES_DESCRIPTION =
  'Show some love to the latest music on Uppbeat, browse our brand new collection of the freshest beats & discover awesome soundtracks before they start trending.';

const getSpecialDescription = (title: string) => {
  switch (title.toLowerCase()) {
    case 'trending':
      return TRENDING_DESCRIPTION;
    case 'undiscovered':
      return UNDISCOVERED_DESCRIPTION;
    case 'brand new in':
    case 'latest releases':
      return LATEST_RELEASES_DESCRIPTION;
    default:
      return '';
  }
};

const getFallbackDescription = (item: Tile) => {
  const title = (item.playlistName ?? item.name).toLowerCase();
  const type = item.url.includes('/sfx/') ? 'sound effects' : 'music';

  return `Download the best royalty-free ${title} ${type} for your content. Safe for YouTube, TikTok, podcasts and social media.`;
};

const getDescription = (item: Tile) => {
  let _description = getSpecialDescription(item.playlistName ?? item.name);

  if (_description.length > 0) {
    return _description;
  }

  _description = (item.description ?? '').trim();

  if (_description.length > 0) {
    return _description;
  }

  _description = (item.short_description ?? '').trim();

  if (_description.length > 0) {
    return _description;
  }

  return getFallbackDescription(item);
};

const GenericTile = ({
  item,
  eventAccess,
  onClick,
  showDescription = true,
}: GenericTileProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const { initializeTracking } = useTracking();
  const { locale } = useRouter();
  const { t } = useTranslate();

  const title = item.playlistName ?? item.name;
  const description = getDescription(item);

  return (
    <DynamicLink
      href={item.url}
      aria-label={item.name}
      onClick={() => {
        if (onClick) {
          onClick();
        }

        void initializeTracking({
          endSlug: item.url,
          content: item.name ?? item.playlistName ?? item.url,
          access: eventAccess ?? 'Carousel',
        });
      }}
      className="group focus:outline-none"
    >
      <div
        className={clsx(
          styles.mainCarouselWrapperTileImage,
          'group-focus-visible:ring-2 group-focus-visible:ring-uppbeat group-focus-visible:ring-offset-4 group-focus-visible:ring-offset-white dark:group-focus-visible:ring-offset-gray-dark-400 ring-opacity-0 group-focus-visible:ring-opacity-100 group-focus-visible:scale-90 transition will-change-transform duration-200 ease-in-out',
        )}
      >
        <Image
          fill
          src={item.tile_image ?? PLACEHOLDER_IMG_URL}
          alt=""
          loading="lazy"
          sizes={[
            '(max-width: 767px) 128px',
            '(max-width: 991px) 248px',
            '(max-width: 1100px) 206px',
            '(max-width: 1600px) 180px',
            '200px',
          ].join(', ')}
          className={clsx(
            styles.tileImage,
            hasLoaded && 'loaded',
            'tile-image',
          )}
          onLoadingComplete={() => setHasLoaded(true)}
        />
        {item.type === 'artist' && (
          <div className={styles.mainCarouselWrapperTileDesc}>{item.name}</div>
        )}
        {!!item.playlistOwnDisplayName && !!item.profile_picture && (
          <div className={styles.mainCarouselWrapperTileDesc}>
            <Image
              src={item.profile_picture}
              alt=""
              width={44}
              height={44}
              sizes="44px"
            />
            {item.playlistOwnDisplayName}
          </div>
        )}
      </div>
      {!!showDescription && (
        <div className="max-w-full block relative">
          {showDescription === 'minimal' ? (
            <h3
              className={clsx({
                'text-white bg-gray-dark-400 font-futura font-normal text-xs left-[10%] bottom-0 absolute transform translate-y-1.5 px-1 py-px rounded dark:text-white dark:bg-gray-dark-200 truncate max-w-[80%]':
                  locale !== 'en',
                'sr-only': locale === 'en',
              })}
              title={t(title)}
            >
              <T>{title}</T>
            </h3>
          ) : (
            <>
              <h3
                className="~text-sm/base font-medium mt-2 mb-1 truncate group-focus-visible:underline"
                title={t(title)}
              >
                <T>{title}</T>
              </h3>
              <div className="~h-8/10 overflow-hidden pr-2">
                <p className="~text-xs/sm whitespace-normal line-clamp-2 text-gray-medium-300 dark:text-gray-medium-200">
                  <T>{description}</T>
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </DynamicLink>
  );
};

export default GenericTile;

import { useRouter } from 'next/router';
import { useState } from 'react';

import { addGAEvent } from '@/utils/analytics/google/addGAEvent';

import type { ReactNode } from 'react';

interface SliderArrowProps {
  children: ReactNode;
  onClick: () => void;
  className: string;
  type?: 'previous' | 'next';
}

export const SliderArrow = ({
  children,
  onClick,
  className,
  type,
}: SliderArrowProps) => {
  const [hasTracked, setHasTracked] = useState(false);

  const router = useRouter();
  const handleClick = () => {
    onClick();

    if (!hasTracked && type) {
      addGAEvent(`carousel-arrow-click-${type}`, {
        value: router.asPath,
      });

      setHasTracked(true);
    }
  };

  return (
    <button
      type="button"
      data-role="none"
      className={className}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

import { clsx } from 'clsx';
import Image from 'next/image';
import { forwardRef, useState } from 'react';

import A from '@/components/A';
import styles from '@/components/Slider/styles.module.css';
import { useTracking } from '@/providers/TrackingProvider';
import { getEnergyColor } from '@/utils/helpers';

import type { RecentDownloadsTile } from '@/types/carousel';

interface BasedOnRecentDlTileProps {
  item: RecentDownloadsTile;
  imagePriority?: boolean;
}

const AVATAR_IMAGE_SIZES = [
  '(max-width: 767px) 60px',
  '(max-width: 991px) 115px',
  '(max-width: 1100px) 95px',
  '(max-width: 1600px) 85px',
  '120px',
].join(', ');

const BasedOnRecentDlTile = forwardRef<
  HTMLDivElement,
  BasedOnRecentDlTileProps
>(({ item, imagePriority }, ref) => {
  const { initializeTracking } = useTracking();
  const [hasLoaded, setHasLoaded] = useState(false);

  const backgroundColor = getEnergyColor(
    item.main_track_energy,
    item.main_track_id,
  );

  const artistAvatar = item.tile_image_main;
  let leftAvatar = item.tile_image_left;
  let rightAvatar = item.tile_image_right;

  if (!leftAvatar) {
    leftAvatar = artistAvatar;
  }

  if (!rightAvatar) {
    rightAvatar = leftAvatar;
  }

  const tileInner = (
    <div className={clsx(styles.carouselContainerInner)} ref={ref}>
      <div className={styles.carouselSpacer} />
      <div className={clsx(styles.carouselArtistAvatar, styles.main)}>
        <Image
          fill
          loading="lazy"
          src={artistAvatar}
          alt=""
          sizes={AVATAR_IMAGE_SIZES}
          className={clsx(hasLoaded && 'loaded', 'tile-image')}
          onLoadingComplete={() => setHasLoaded(true)}
        />
      </div>
      <div className={clsx(styles.carouselArtistAvatar, styles.left)}>
        <Image
          fill
          priority={imagePriority}
          src={leftAvatar}
          alt=""
          sizes={AVATAR_IMAGE_SIZES}
        />
      </div>
      <div className={clsx(styles.carouselArtistAvatar, styles.right)}>
        <Image
          fill
          priority={imagePriority}
          src={rightAvatar}
          alt=""
          sizes={AVATAR_IMAGE_SIZES}
        />
      </div>
      <div className={styles.carouselMainTrack}>
        <strong>{item.main_track_name}</strong>
        <br />
        <span>{item.main_track_artist}</span>
      </div>
    </div>
  );

  if (item.url !== '') {
    return (
      <A
        onClick={() => {
          void initializeTracking({
            endSlug: item.url,
            content: item.name ?? item.url,
            access: 'Carousel',
          });
        }}
        href={item.url}
        className={clsx(
          styles.carouselContainerMain,
          styles.mainCarouselAnchorWrapperTileImage,
          styles.isAnchor,
        )}
        style={{
          background: backgroundColor,
        }}
        aria-label={item.name}
      >
        {tileInner}
      </A>
    );
  }

  return (
    <div
      className={clsx(
        styles.carouselContainerMain,
        styles.mainCarouselWrapperTileImage,
      )}
      style={{
        background: backgroundColor,
      }}
      aria-label={item.name}
    >
      {tileInner}
    </div>
  );
});

BasedOnRecentDlTile.displayName = 'BasedOnRecentDlTile';

export default BasedOnRecentDlTile;
